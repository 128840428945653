var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"message"}},[_c('div',{staticClass:"zh-header"},[_c('van-row',{attrs:{"gutter":"10"}},[_c('van-col',{staticClass:"header-title",attrs:{"span":"10"},on:{"click":_vm.onClickLeft}},[_c('svg-icon',{attrs:{"data_iconName":'back-default',"className":'back_icon'}}),_vm._v("培训管理 ")],1)],1)],1),_c('div',{staticClass:"message-container"},[_c('van-pull-refresh',{on:{"refresh":_vm.onRefresh},model:{value:(_vm.refreshing),callback:function ($$v) {_vm.refreshing=$$v},expression:"refreshing"}},[(_vm.list.length > 0)?_c('van-list',{attrs:{"finished":_vm.finished,"finished-text":"没有更多了"},on:{"load":_vm.get_notifications},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}},_vm._l((_vm.list),function(item,index){return _c('div',{key:index,class:[item.is_read ? 'issue-item-read' : 'issue-item'],on:{"click":function($event){return _vm.onIssueItemClick(item)}}},[_c('van-row',[_c('van-col',{attrs:{"span":"14"}},[_c('div',{class:[
                  item.is_read
                    ? 'issue-item-title-read van-ellipsis'
                    : 'issue-item-title van-ellipsis',
                ]},[_vm._v(" "+_vm._s(item.training.title ? item.training.title : "没有标题")+" ")])]),_c('van-col',{attrs:{"span":"10"}},[_c('div',{class:[
                  item.is_read
                    ? 'issue-item-date text-read'
                    : 'issue-item-date',
                ]},[_vm._v(" "+_vm._s(item.created_at)+" ")])])],1),(!item.relation.is_read)?_c('div',{class:['dot']}):_vm._e()],1)}),0):_vm._e(),(_vm.list.length == 0)?_c('van-empty',{attrs:{"description":"暂无数据"}}):_vm._e()],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }